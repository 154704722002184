
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$mobileScreen : 480px;
$tabScreen : 768px;
$midLapScreen : 992px;
$lapScreen : 1200px;
$midNormal	:	1441px;
$normalScreen : 1680px;
body {
	font-family: 'agnregular';
	font-size: 15px;
	background-color: #f7f8f8;
}
p,li {
	font-size: 15px;
	line-height: 20px;
	font-family: 'agnlight';
}
a {
	text-transform: none;
}
.top-nav {
	background-color: #fff;
	padding: 2px 0;
	box-shadow: 0px 3px 6px #adacac;
}
.navbar-default {
	background-color: #f7f8f8;
	border: none;
	margin-bottom: 0;
}
.navbar-brand {
	height: 98px;
	padding: 15px 18px;
}
.topRightNav {
	font-size: 14px;
}
.topRightNav.navbar-nav {
	margin-top: 17px;
	float: none;
	@media screen and (max-width: $mobileScreen) {
	    margin-top: 0;
	    display: none;
	}
}
.topRightNav.navbar-nav>li {
	float: left;
}
.topRightNav.navbar-nav>li>a {
	color: #454545;
}
.topRightNav.navbar-nav>li>form {
	padding: 5px 10px 14px 10px;
}
.topRightNav.navbar-nav>li>form .input-group-addon{
	background: none;
	padding: 0 5px;
	border: 0;	
}
.topRightNav.navbar-nav>li input {
	border: 0;	
}
.searchBar {
	.inputFiled {
		overflow: hidden;
		width: 0;
		display: inline-block;
	}
	input[type="text"] {
		padding: 4px 8px;
		border: 1px solid #ccc;
	}
	.searchButton {
		display: inline-block;
	}
}
.slogan{
	font-size: 14px;
	@media screen and (min-width: $mobileScreen) {
		margin-right: 15px;
	}
	padding-right: 90px;
	color: #005aab;
	background: url("/images/slogan-bar.jpg") right center no-repeat;
}
.mainNav,.dropdown-menu {
	background-color: #005aab;
}
.mainNav {
	.container {
		position: relative;
	}
	.navbar-nav {
		margin-top: 0;
		margin-left: -15px;
		margin-right: -15px;
	}
	.nav {
		@media screen and (min-width: $tabScreen) {
			background: url('/images/nav-devider.jpg') right 0 no-repeat;
		}
	}
	.nav>li{
		@media screen and (min-width: $tabScreen) {
			background: url('/images/nav-devider.jpg') left 0 no-repeat;
		}
	}	
	.nav li>a {
		font-size: 14px;
		color: #fff !important;
		@media screen and (min-width: $tabScreen) and (max-width: $midLapScreen) {
			padding: 15px 9.3px;
			font-size: 13px;
			//padding: 15px 8.8px;
		}
		@media screen and (min-width: $midLapScreen) and (max-width: $lapScreen) {
			padding: 15px 21.9px;
			//padding: 15px 24.5px;
		}
		@media screen and (min-width: $lapScreen){
			padding: 15px 47px;
			//padding: 15px 38.8px;
		}
	}
	/* .dropdown-menu {
		width: calc(100% - 30px);
		left: 0;
		position: absolute;
		margin: 0 15px;
	}
	.dropdown-menu li {
		display: block;
	} */
	.dropdown-menu li>a {
		padding: 8px 10px;
		font-size: 14px;
		text-transform: uppercase;
		display: block;
	}
	.nav li:hover > a, li.active > a,.open > a {
		background-color: #0090d6 !important;
	}
	.visible-inline-xs {
		display: none;
		@media screen and (max-width: $mobileScreen) {
			display: inline-block;
		}
	}
	.row div+div {
		background: url('/images/nav-devider.jpg') left 0 repeat-y;
		min-height: 215px;
	}
}
.banner {
	border-bottom: 2px solid #005aab;
	position: relative;
	.slider {
		overflow: hidden;
		max-height: 415px;
		@media screen and (max-width: 769px) {
			max-height: 210px;
		}
		@media screen and (min-width: 769px) and (max-width: $lapScreen) {
			max-height: 220px;
		}
		@media screen and (min-width: $lapScreen) and (max-width: $midNormal) {
			max-height: 315px;
		}
		@media screen and (min-width: $midNormal) and (max-width: $normalScreen) {
			max-height: 360px;
		}
	}	
	.owl-prev,
	.owl-next {
		position: absolute;
		top: 38%;
		margin-top: -10px; // Half of the prev/next buttons height here in negatives to center them perfectly
	}
	.owl-prev {
		left: 0;
	}
	.owl-next {
		right: 0;
	}
}
.content {
	padding: 15px;
	@media screen and (max-width: $mobileScreen) {
	    margin-left: -15px;
	    margin-right: -15px;
	}
}
.whiteBox {
	background-color: #fff;
	margin-top: 20px;
	margin-bottom: 20px;
	h1 {
		font-size: 27px;
		color: #005aab;
		margin-top: 0;
	}
	p,ul,li {
		color: #7e8082;
	}
} 
.readMore a{
	display: inline-block;
	background: url('/images/readMoreArrow.png') right center no-repeat;
	padding-right: 20px;
	font-family: 'agnbold';
	font-size: 14px;
	color: #7e8082;
}
.twoBox {
	padding-top: 20px;
}
.grayBox {
	background-color: #f8f8f8;
	padding: 15px;
	@media screen and (max-width: $tabScreen) {
		margin-top: 10px;
	}
	.grayBox_header {
		background:transparent url('/images/grayBox_border.jpg') left bottom repeat-x;
		padding-bottom: 5px;
		overflow: hidden;
		margin-bottom: 15px;
	}
	.grayBox__headerIcon {
		display: inline-block;
		padding-right: 10px;
		vertical-align: middle;
	}
	.grayBox__headerText {
		display: inline-block;
		font-family: 'agnbold';
		font-size: 17px;
		color: #333333;
		vertical-align: bottom;
		margin: 0;
	}
	.grayBox__sliderArrow {
		display: inline-block;
	}
	.grayBox_content h3{
		margin: 0;
		padding-bottom: 10px;
		font-size: 16px;
		font-family: 'agnmedium';
		color: #005aab;
	}
	.grayBox_content p {
		line-height: 18px;
		padding-bottom: 8px;
		margin: 0;
	}
	.owl-nav {
		position: absolute;
		top: -45px;
		right: 0;
	}
	.owl-prev,.owl-next {
		display: inline-block;
		padding-left: 7px;
	}
}
.core-services {
	padding-top: 10px;
	h2 {
		color: #005aab;
		font-size: 24px;
		font-weight: normal;
	}
	.serviceSlider {
		padding-top: 10px;
		position: relative;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
	}
	li {
		display: block;
		float: left;
	}
	.serviceBox {
		background-color: #f8f8f8;
		margin-bottom: 10px;
	}
	.serviceContent {
		padding: 9px;
	}
	h3 {
		margin: 0;
		padding-bottom: 5px;
	}
	.serviceImage {
		width:100%;
		height: 125px;
		overflow: hidden;
	}
	.serviceImage img {
		width: 100%;
	}
	.arrow {
		float: left;
		width: 25px;
		position: relative;
		vertical-align: middle;
		height: 100%
	}
	.arrow img {
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}
	.coreServiceSlider {
		position: relative;
		@media screen and (min-width: $tabScreen) {
			padding: 0 25px;
		}
	}
	.owl-prev,
	.owl-next {
		position: absolute;
		top: 50%;
		margin-top: -10px; // Half of the prev/next buttons height here in negatives to center them perfectly
	}
	.owl-prev {
		left: 0;
	}
	.owl-next {
		right: 0;
	}
	.serviceContent h3 {
		color: #005aab;
		font-size: 16px;
		font-family: 'agnmedium';
	}
}
.newsContent {
	h2 {
		color: #005aab;
		font-size: 24px;
		font-weight: normal;
	}
	a {
		text-decoration: none;
	}
	.newsBox {
		background-color: #fff;
		margin-bottom: 10px;
	}
	h3 {
		font-size: 15px;
		font-family: 'agnmedium';
		margin: 0;
	}
	.newsSliderContent {
		padding: 8px;
		float: left;
		width: calc(100% - 66px);
	}
	.newsSliderContent p {
		font-size: 14px;
		margin-bottom: 0;
		padding-top: 10px;
	}
	.newsDate {
		background-color: #969fa7;
		color: #fff;
		font-size: 15px;
		text-align: center;
		position: relative;
		line-height: 16px;
		font-family: 'agnlight';
		float: left;
		width: 50px;
	}
	.newsDate b {
		font-family: 'agnmedium';
		font-size: 18px;
		display: block;
		padding-top: 14px;
	}
	.newsDate span {
		background-color: #005aab;
	    display: block;
	    font-size: 13px;
	    padding: 3px 10px;
	    font-family: 'agnregular';
	    margin-top: 10px;
	}
	.readMore {
		padding-top: 10px;
	}
}
footer {
	background-color: #005aab;
	padding: 10px 0;
	color: #fff;
	li a {
		color: #fff !important;
	}
	p {
		margin-bottom: 0;
	}
	.socialLinks {
		padding-top: 10px;
	}
	.socialLinks a {
		display: inline-block;
		margin-left: 5px;
	}
}
/** About Us Page */
.innerBanner {
	width: 100%;
	height: 249px;
	overflow: hidden;
	background-position: center;
}
.companyImage {
	// border: 5px solid #7e8082;
}
.grayContent {
	background-color: #f3f3f3;
	margin-top: 20px;
	margin-bottom: 20px;
	.core-services {
		padding-top: 0;
	}
	h2 {
		margin-top: 0;
	}
}
/** Services Page */
.service-container {
	.serviceBox {
		background-color: #f8f8f8;
		width: 100%;
		margin-bottom: 25px;
		box-shadow: -1px 3px 5px #dbdbdb;
	}
	.serviceBox_image {
		height: 150px;
		overflow: hidden;
	}
	.serviceBox_content {
		padding: 10px;
	}
	h3 {
		margin: 0;
		padding-bottom: 10px;
		font-size: 16px;
		font-family: 'agnmedium';
		color: #005aab;
	}
}
/** single Service */
.singlePage {
	padding: 0;
	.whiteBox {
		padding: 15px;
	}
	.singlePageContent {
		padding-top: 15px;
	}
	.sidebarHead {
		background: url('/images/sideheadBorder.jpg') left bottom repeat-x;
		margin-bottom: 10px;
		padding-bottom: 8px;
	}
	.sidebarHead h2{
		margin: 0;
		font-size: 17px;
		color: #333;
		font-family: 'agnmedium';
	}
	h3 {
		font-size: 18px;
		font-family: 'agnmedium';
	}
	.contentSlider {
		@media screen and (max-width: $mobileScreen) {
			max-height: 200px;
		}
		@media screen and (min-width: $tabScreen) and (max-width: $midLapScreen) {
			max-height: 250px;
		}
		@media screen and (min-width: $midLapScreen) and (max-width: $lapScreen) {
			max-height: 350px;
		}
		@media screen and (min-width: $lapScreen){
			max-height: 450px;
		}
		overflow: hidden;
		padding: 0;
	}
	.owl-prev,.owl-next {
		position: absolute;
		top: 47%;
		padding: 10px;
		background-color: rgba(255,255,255,.8);
	}
	.owl-prev {
		left: 0;
	}
	.owl-next {
		right: 0;
	}
	.contentSlider p {
		background-color: rgba(0,0,0,.8);
		position: absolute;
		width: 100%;
		padding: 10px;
		margin: 0;
		color: #fff;
		z-index: 999;
		@media screen and (max-width: $mobileScreen) {
			top: 155px;
		}
		@media screen and (min-width: $tabScreen) and (max-width: $midLapScreen) {
			top: 210px;
		}
		@media screen and (min-width: $midLapScreen) and (max-width: $lapScreen) {
			top: 310px;
		}
		@media screen and (min-width: $lapScreen){
			top: 410px;
		}
	}
	/* .owl-carousel .owl-item img {
		width: auto !important;
		height: auto !important;
		max-width: 100% !important;
		max-height: 100% !important;
	}
	.owl-item {
		max-height: 450px;
	} */
}
.otherServices {
	.otherServices_image {
		display: inline-block;
		width: 70px;
	}
	.row {
		margin-left: -5px;
		margin-right: -5px;
		margin-bottom: 10px;
	}
	.col-xs-5,.col-xs-7 {
		padding-left: 5px;
		padding-right: 5px;
	}
	h3 {
		font-size: 16px;
		margin: 0;
		margin-top: 10px;
		padding-bottom: 5px;
		border-bottom: 1px solid #f9f9f9;
	}
	p {
		margin-bottom: 5px;
	}
}
/** Industry Applications */
.industry-container {
	.industryBox {
		background-color: #f8f8f8;
		width: 100%;
		margin-bottom: 25px;
		padding: 10px;
		box-shadow: -1px 3px 5px #dbdbdb;
	}
	.industryBox_image {
		overflow: hidden;
	}
	.industryBox_content {
		padding: 0 10px 0 0;
		min-height: 123px;
	}
	h3 {
		margin: 0;
		padding-bottom: 10px;
		font-size: 16px;
		font-family: 'agnmedium';
		color: #005aab;
	}
	.readMore {
		margin-right: 10px;
	}
}
/** Quality Focus */
.qualityCertifications {
	padding-top: 10px;
	margin-left: -10px;
	margin-right: -10px;
	img {
		display: inline-block;
		margin: 10px;
	}
}
/** Gallery Box */
.galleryBox {
	background-color: #f1f1f1;
	margin-bottom: 15px;	
	.galleryBox_title {
		padding: 1px 5px;
	}
	.galleryBox_image {
		position: relative;
		width: 100%;
	}
	.galleryBox_image img{
		display: block;
		width: 100%;
		height: auto;
	}

	.overlay {
		position: absolute;
		display: block;
		text-decoration: none;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.6);
		overflow: hidden;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: .5s ease;
	}

	.galleryBox_image:hover .overlay {
		opacity: 1;
	}

	.galleryBox_hovertext {
		color: white;
		font-size: 14px;
		padding: 10px;
		padding-top: 5px;
	}
}
.galleryTags {
	.input-group-addon {
		padding: 0;
		line-height: 0;
	}
	.btn {
		line-height: 0;
	}
	.row {
		margin-left: -5px;
		margin-right: -5px;
	}
	.col-sm-6 {
		padding-left: 5px;
		padding-right: 5px;
		margin-bottom: 10px;
	}
	a {
		font-size: 14px;
		font-family: 'agnlight';
		color: #666;
		border: 1px solid #e9e9e9;
		border-radius: 5px;
		display: block;
		text-align: center;
		text-decoration: none;
		transition: .5s ease;
	}
	a:hover,a.active {
		background-color: #e9e9e9;
		color: #666;
	}
	.clearFilter {
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		margin-bottom: 15px;
		margin-top: 30px;
		text-align: center;
		height: 18px;
		a {
			margin: -3px 25% 0px 25%;
			background-color: #ccc;
			border: 0;
			color: #414040;
		}
	}
}
.contactPage {
	padding: 20px 0 0;
	.whiteBox {
		padding: 15px;
		margin-top: 0;
	}
	form label {
		font-family: 'agnmedium';
		font-size: 14px;
		color: #333333;
		font-weight: normal;
	}
	.locationMap {
		height: 500px;
		@media screen and (max-width: $mobileScreen) {
			height: 250px;
		}
		overflow: hidden;
		background-color: #fff;
	}
	.sidebarHead {
		background: url('/images/sideheadBorder.jpg') left bottom repeat-x;
		margin-bottom: 10px;
		padding-bottom: 8px;
	}
	.sidebarHead h2{
		margin: 0;
		font-size: 17px;
		color: #333;
		font-family: 'agnmedium';
	}
	.locationAddress {
		color: #444;
	}
	.locationAddress h3 {
		font-size: 13px;
		font-family: 'agnbold';
		margin: 5px 0 7px 0;
	}
	.locationAddress p {
		font-size: 12px;
		font-family: 'agnregular';
		line-height: 17px;
		margin-bottom: 3px;
	}
	.locationAddress  span {
		font-size: 12px;
		font-family: 'agnregular';
		display: block;
	}
	.locationAddress  i {
		font-size: 13px;
		display: inline-block;
		margin-right: 5px;
	}
	.locationAddress a {
		color: #444444;
		text-decoration: none;
	}
}
.countryList select{
	display: block;
	width: 100%;
	height: 36px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.6;
	color: #555555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccd0d2;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.careerPage {
	hr {
		margin-top: 10px;
	}
	h4 {
		margin-top: 15px;
		font-family: "agnmedium";
	}
}
.news-container {
	.newsBox {
		background-color: #f8f8f8;		
	}
	.newsBox_image {
		max-height: 150px;
		overflow: hidden;
		border: 3px solid #f0efef;
	}
	.newsBox_content {
		padding: 10px;
	}
	h3 {
		font-family: "agnmedium";
		font-size: 15px;
		color: #0074bd;
	}
	.newsBox_date {
		background-color: rgba(66,72,76,.8);
		color: #fff;
		font-size: 15px;
		text-align: center;
		position: relative;
		line-height: 16px;
		font-family: 'agnlight';
		float: left;
		width: 50px;
		position: absolute;
		margin-top: -60px;
		margin-left: 2px;
	}
	.newsBox_date b {
		font-family: 'agnmedium';
		font-size: 18px;
		display: block;
		padding-top: 14px;
	}
	.newsBox_date span {
		background-color: #005aab;
	    display: block;
	    font-size: 13px;
	    padding: 3px 10px;
	    font-family: 'agnregular';
	    margin-top: 10px;
	}
}
.singlePage.newsContent {
	.whiteBox {
		padding-top: 0;
	}
	h1 {
		float: left;
		padding-left: 5px;
		padding-top: 10px;
	}
	.newsHead {
		padding-bottom: 15px;
	}
	.sidebarHead {
		padding-top: 15px;
	}
	.otherNews .newsDate {
		width: 40px;
	}
	.otherNews b {
		padding-top: 5px;
	}
	.otherNews span {
		padding: 3px 0;
		margin-top: 5px;
	}
	.otherNews h3 {
		padding-left: 50px;
		padding-top: 10px;
	}
	.otherNews .newsHead {
		padding-bottom: 10px;
	}
}
.singlePage.searchPage {
	h1 {
		float: none;
	}
	.newsBox_date {
		background-color: rgba(66,72,76,.8);
		color: #fff;
		font-size: 15px;
		text-align: center;
		position: relative;
		line-height: 16px;
		font-family: 'agnlight';
		float: left;
		width: 50px;
		position: absolute;
		margin-top: -60px;
		margin-left: 2px;
	}
	.newsBox_date b {
		font-family: 'agnmedium';
		font-size: 18px;
		display: block;
		padding-top: 14px;
	}
	.newsBox_date span {
		background-color: #005aab;
	    display: block;
	    font-size: 13px;
	    padding: 3px 10px;
	    font-family: 'agnregular';
	    margin-top: 10px;
	}
	.searchBox + .searchBox {
		margin-top: 15px;
	}
}
.fearuredImage ul {
	list-style: none;
}
.paginate {
	.pagination {
		margin-bottom: 0;
	}
}
.content .pagination {
	margin: 0;
}
.singlePageContent .shadowBox {
	box-shadow: 0px 0px 5px #ccc;
	padding: 10px;
	margin-bottom: 10px;
}
.singlePageContent {	
	.nav-tabs {
		border: 0;
		margin-bottom: 15px;
	}
	.nav-tabs li {
		margin: 0;
	}
	.nav-tabs li > a {
		border: 0 !important;
		border-radius: 0;
		padding: 10px 30px;
		background-color: #e7e7e7;
		color: #333;
	}
	.nav-tabs li.active > a,.nav-tabs li:hover > a   {
		color: #fff !important;
		background-color: #969fa7 !important;
		box-shadow: 2px 2px 2px #ccc;
	}
}